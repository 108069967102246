.extra-tasks__item {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: 130px;
    //border: 2px solid $gray-lighter;
    background: #f6f6f6;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-bottom: 2px solid $brand-info;
        transform: scale(0,1);
        transition: transform .3s ease-in-out;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        transform: scale(1,0);
        transform-origin: center bottom;
        background: linear-gradient(135deg, rgba($brand-info, 0.6) 0%, $brand-info 50%, $brand-info 100%);
        transition: opacity .3s ease-in-out, transform 0.2s;
    }

    &:hover {
        //border: 2px solid $brand-info;
        &:before {
            transform: scale(1,1);
        }
        .extra-tasks__duration {
            //margin-left: 50px;
            transform: translate3d(-15px,0,0);
            //@include transition-delay(0.1s);
            opacity: 1;
        }
        .extra-tasks__icon {
            img {
                transform: translate3d(-40px,0,0);
            }
        }
    }

    &--active {

        //border: 2px solid $brand-info;
        //background: $brand-info;
        &:before {
            transform: scale(1,1);
        }
        &:after {
            opacity: 1;
            transform: scale(1,1);
        }
        color: #fff;

        .extra-tasks__duration {
            transform: translate3d(-5px,0,0);
            opacity: 1;
        }

        .extra-tasks__icon {
            img {
                transform: translate3d(-40px,0,0) scale(1.15);
            }
        }

        &:hover {
            .extra-tasks__duration {
                transform: translate3d(-5px,0,0);
            }
        }
    }
}

.extra-tasks__label {
    z-index: 2;
    position: relative;
    margin-bottom: 15px;
    font-size: 1.15em;
}

.extra-tasks__icon {
    z-index: 2;
    position: relative;
    img {
        margin-left: 0;
        @include transition(all 0.3s);
        max-height: 4em;
    }
}

.extra-tasks__duration {
    z-index: 2;
    position: absolute;
    width: 100%;
    left: 0;
    top: 55%;
    font-size: 1.2em;
    margin-left: 65px;
    text-align: center;
    opacity: 0;
    @include transition(all 0.3s);
}







// PRIMARY THEME
.extra-task__primary {

    &:before {
        border-bottom: 2px solid $brand-primary;
    }

    &:after {
        background: linear-gradient(135deg, rgba($brand-primary, 0.6) 0%, $brand-primary 50%, $brand-primary 100%);
    }
}
/////////////////

// SUCCESS THEME
.extra-task__success {

    &:before {
        border-bottom: 2px solid $brand-success;
    }

    &:after {
        background: linear-gradient(135deg, rgba($brand-success, 0.6) 0%, $brand-success 50%, $brand-success 100%);
    }
}
/////////////////

// Danger THEME
.extra-task__danger {

    &:before {
        border-bottom: 2px solid $brand-danger;
    }

    &:after {
        background: linear-gradient(135deg, rgba($brand-danger, 0.6) 0%, $brand-danger 50%, $brand-danger 100%);
    }
}
/////////////////

// WARNING THEME
.extra-task__warning {

    &:before {
        border-bottom: 2px solid $brand-warning;
    }

    &:after {
        background: linear-gradient(135deg, rgba($brand-warning, 0.6) 0%, $brand-warning 50%, $brand-warning 100%);
    }
}
/////////////////












.frequency {
}

.frequency__item {
    min-height: 110px;
    border: 2px solid $gray-lighter;
    text-align: center;
    padding: 10px;
    transition: background 0.2s;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;

    &:hover {
        border: 2px solid $brand-info;
    }

    &--active {
        border: 2px solid $brand-info;
        background: $brand-info;
        color: #fff;

    }
}
.frequency__label {
    font-weight: 600;
    margin-bottom: 10px;
}
.frequency__price {
    font-size: 24px;
}

.extra-tasks__duration_table{
    z-index: 2;
    width: 100%;
    left: 0;
    font-size: 1.2em;
    text-align: center;
}

.extra-tasks__item_table {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    height: 100%;
    //border: 2px solid $gray-lighter;
    background: #f6f6f6;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-bottom: 2px solid $brand-info;
        transform: scale(0,1);
        transition: transform .3s ease-in-out;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        transform: scale(1,0);
        transform-origin: center bottom;
        background: linear-gradient(135deg, rgba($brand-info, 0.6) 0%, $brand-info 50%, $brand-info 100%);
        transition: opacity .3s ease-in-out, transform 0.2s;
    }

    &:hover {
        //border: 2px solid $brand-info;
        &:before {
            transform: scale(1,1);
        }
        .extra-tasks__duration {
            //margin-left: 50px;
            transform: translate3d(-15px,0,0);
            //@include transition-delay(0.1s);
            opacity: 1;
        } 
    }
}


.extra-task__disabled {
    color:lightgray !important;
}