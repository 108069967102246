/* iCheck plugin Line skin, blue
----------------------------------- */

.icheckbox_line-blue,
.iradio_line-blue {
	background: #18b5fe;
}

.icheckbox_line-blue.hover,
.icheckbox_line-blue.checked.hover,
.iradio_line-blue.hover {
    background: #019be2;
}

.icheckbox_line-blue.checked,
.iradio_line-blue.checked {
    background: #18b5fe;
}
    
.icheckbox_line-blue.disabled,
.iradio_line-blue.disabled {
    background: #18b5fe;
    cursor: not-allowed;
    opacity: 0.65;
}

.icheckbox_line-blue.disabled .icheck_line-icon,
.iradio_line-blue.disabled .icheck_line-icon {
    background-position: -30px 0;
}

.icheckbox_line-blue.checked.disabled,
.iradio_line-blue.checked.disabled {
    background: #18b5fe;
    cursor: not-allowed;
    opacity: 0.65;
}

