.navbar-brand{
    width: 115px;
    margin: 0;
    padding:0;
}

.topbar-moppi {
    background: none;
    border: none;
    padding:0;
    margin:10px 0;
    border-radius:0;
}

.topbar-moppi .navbar-nav > li > a {
    color: #95999f;
    background:none;
    font-size: 16px;
    font-weight: 600;
}

.topbar-moppi .navbar-nav > li.active > a {
    color: #555758;
}

.topbar-moppi .navbar-nav li > a:hover, .topbar-moppi .topbar-nav li > a:focus{
    text-decoration: none !important; 
    transition: all 1s ease !important; 
    color:#555758 !important; 
}





