.fc-event {
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    background-color: #5bc0de;
    border-color: #46b8da;
    font-weight: normal;
    pointer-events: all;
    cursor: pointer;
}

.fc-event-done {
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    background-color: #32c17b;
    border-color: #2dad6e;
    font-weight: normal;
}
.fc-bgevent-skeleton{
     pointer-events: none;
}
.fc-content-skeleton{
     pointer-events: none;
}

.fc-bgevent {
    cursor: pointer !important;
    z-index: 999;
    pointer-events: all;
}
.fc-bgevent:hover {
   opacity: .5;
}

.not-available{
    background: #FF7171;
}

.available{
 
}
