@keyframes spinner {
    to {transform: rotate(360deg);}
}
 
@-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg);}
}
 
.spinner {
    display: inline-block;
    vertical-align: middle;
    float:right;
    position: relative;
}
.spinner-35 {
    min-width: 35px;
    min-height: 35px;
}

.spinner-28 {
    min-width: 28px;
    min-height: 28px;
}

.spinner:before {
    content: ' ... ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
}
 
.spinner:not(:required):before {
    content: '';
    border-radius: 50%;
    border-top: 2px solid $brand-primary;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
    -webkit-animation: spinner .6s linear infinite;
}

.spinner-100 {
    min-width: 150px !important;
    min-height: 150px !important;
    float:inherit !important;
    text-align:center;
}
 

.spinner-100:before {
    content: ' ... ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px !important;
    height: 60px !important;
    margin-top: -30px !important;
    margin-left: -30px !important;
}

.spinner-100:not(:required):before {
    content: '';
    border-radius: 50%;
    border-top: 4px solid $brand-primary !important;
    border-right: 4px solid transparent !important;
    animation: spinner .6s linear infinite !important;
    -webkit-animation: spinner .6s linear infinite !important;
}