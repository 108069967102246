@font-face {
    font-family: Varela Round;
    src: url('/fonts/Varela_Round/VarelaRound-Regular.ttf');
}

@import "moppi_variables";

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
//@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
//@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
//@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

//fontawesome
$icon-font-path: "/fonts/bootstrap/";
$fa-font-path:   "/fonts/fontawesome";
@import "bootstrap";
@import "font-awesome";

//Moppi components
@import "components/layout";
@import "components/login-form";
@import "components/romeDatePicker";
@import "components/leftnav"; 
@import "components/loader";
@import "components/navbar";
@import "components/topbar";
@import "components/calendar";
@import "components/booking";
@import "components/timeline";
@import "components/buttons";
@import "components/icheck-blue";
