
.navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.navbar-nav > li {
    margin-right: 45px;
}

.navbar-moppi {
    background-color: #555758;
    border-color: #555758;
    border: none;
    padding:0;
    margin:0;
    border-radius:0;
}

.navbar-moppi .navbar-nav > li > a {
    color: #95999f;
    font-size: 16px;
}

.navbar-moppi .navbar-nav > li.active > a {
    color: #fff;
    font-weight: 600;
}

.navbar-moppi .navbar-brand{
    width: 115px;
    margin: 0 15px;
    padding: 5px 0;
}

.navbar-moppi .navbar-nav li > a:hover, .navbar-moppi .navbar-nav li > a:focus{
    text-decoration: none !important; 
    transition: all 1s ease !important; 
    color:#fff !important; 
    background-color: #555758 !important; 
}



@media(min-width:768px){

    .navbar-moppi .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
        padding:0;
    }

   //TRIANGLE POINTER
    .navbar-moppi .navbar-nav li.active:after, .navbar-nav li.active:before {
        bottom: 0%;
        margin-bottom: -2px;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        color: #fff;
    }

    .dropdown-menu li.active:after, .dropdown-menu li.active:before {
        border: none !important;
    }

    .navbar-moppi .navbar-nav li.active:after {
        border-bottom-color: #f9f9f9;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1;
    }
}

@media(max-width:768px){
    #login-dp{
         background-color: #eee;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 32px;
        color: #555758;
    }
    #login-dp .bottom{
        background-color: inherit;
        border-top:0 none;
    }

    .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
        text-decoration: none;
        background-color: #95999f;
        transition: all 1s ease;
     }

}


///////////// TOGGLE NAVBAR ////////////////////
.navbar-toggle:hover, .navbar-toggle:focus {
    background-color: #333;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #ddd;
}
.navbar-default .navbar-toggle {
    border-color: #ddd;
}
.navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: #555758;
    background-image: none;
    border: 1px solid #555758;
    border-radius: 4px;
}


////////// DROPDOWN MENU /////////////////////
.dropdown-menu > li > a {
    display: block;
    padding: 12px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.6;
    color: #555758;
    white-space: nowrap;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
    color: #555758;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: #777777;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    text-decoration: none;
    background-color: #E0E0E0;
    transition: all 1s ease;
}





// Huomenta styles
.navbar-huomenta {
    background-color: #fff;
    border-color: #e4e4e4;
    border: none;
    padding:0;
    margin:0;
    border-radius:0;
}

.navbar-huomenta .navbar-nav > li > a {
    color: #95999f;
    font-size: 16px;
}

.navbar-huomenta .navbar-nav > li.active > a {
    color: #555;
    font-weight: 600;
}

.navbar-huomenta .navbar-brand{
    width: 115px;
    margin: 0 15px;
    padding: 5px 0;
}

.navbar-huomenta .navbar-nav li > a:hover, .navbar-huomenta .navbar-nav li > a:focus{
    text-decoration: none !important; 
    transition: all 1s ease !important; 
    color:#fff !important; 
    background-color: #555758 !important; 
}



@media(min-width:768px){

    .navbar-huomenta .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
        padding:0;
    }

   //TRIANGLE POINTER
    .navbar-huomenta .navbar-nav li.active:after, .navbar-nav li.active:before {
        bottom: 0%;
        margin-bottom: -2px;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        color: #fff;
    }

    .dropdown-menu li.active:after, .dropdown-menu li.active:before {
        border: none !important;
    }

    .navbar-huomenta .navbar-nav li.active:after {
        border-bottom-color: #f9f9f9;
        border-width: 10px;
        margin-left: -10px;
        z-index: 1;
    }
}

