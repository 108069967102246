//OUTLINE

.btn-w-m {
  min-width: 120px;
}
.btn-outline{
    background:none;
}
.btn-primary.btn-outline {
  color: $brand-primary;
}
.btn-success.btn-outline {
  color: $brand-success;
}
.btn-info.btn-outline {
  color: $brand-info;
}
.btn-warning.btn-outline {
  color: $brand-warning;
}
.btn-danger.btn-outline {
  color: $brand-danger;
}
.btn-outline:hover, 
.btn-outline:active, 
.btn-outline:focus {
  color: #fff;
}


// ROUND

.btn-round-lg{
border-radius: 22.5px;
}
.btn-round{
border-radius: 17px;
}
.btn-round-sm{
border-radius: 15px;
}
.btn-round-xs{
border-radius: 11px;
padding-left: 10px;
padding-right: 10px;
}


.btn-round.btn-outline, .btn-round-xs.btn-outline, .btn-round-lg.btn-outline, .btn-round-sm.btn-outline{
  font-weight: bold;
}

.btn-black {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.60);
  border:none;
}

.btn-hover {
	bottom: 0%;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

    
