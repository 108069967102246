
.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  background-color: #2e353d;
  position: fixed;
  width:inherit;
  top: 0px;
  height: 100%;
  color: #fff;
  border-right: 1px solid #ddd;
}

.nav-side-menu .logo {
  margin-bottom: 16px;
  margin-top: 16px;
  height: 60px;
}

.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
  /*    
    .collapsed{
       .arrow:before{
                 font-family: FontAwesome;
                 content: "\f053";
                 display: inline-block;
                 padding-left:10px;
                 padding-right: 10px;
                 vertical-align: middle;
                 float:right;
            }
     }
*/
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid $brand-primary;
  background-color: #4f5b69;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: $brand-primary;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: $brand-primary !important;
  font-weight: 800;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #020203;
}
.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  width: 100%;
}

.sub-menu li a {
 width:85%;
}
.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li:hover {
  border-left: 3px solid darken($brand-primary, 20%);
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

body {
  margin: 0px;
  padding: 0px;
}


.nav-side-menu ul .sub-menu li.darken-active a,
.nav-side-menu li .sub-menu li.darken-active a {
  color: $brand-primary;
}

.nav-side-menu ul.darken-active,
.nav-side-menu li.darken-active {
  border-left: 3px solid darken($brand-primary, 20%) !important;
  background-color: #4f5b69;
}

@media (max-width: 767px) {
    .nav-side-menu {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
    }
    .nav-side-menu .toggle-btn {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 45px;
      z-index: 10 !important;
      padding: 3px;
      background-color: #181c20;
      color: $brand-primary;
      width: 40px;
      text-align: center;
    }
    .brand {
      text-align: left !important;
      font-size: 22px;
      line-height: 50px !important;
    }

    .brand .logo {
      padding-left: 20px;
      height: 40px !important;
    }
}

@media (min-width: 767px) {
    .nav-side-menu .menu-list .menu-content {
      display: block;
    }
}






























/// HUOMENTA //////////////////


.nav-side-menu.huomenta .logo {
  margin-bottom: 16px;
  margin-top: 16px;
  height: 60px;
}

.nav-side-menu.huomenta .toggle-btn {
  display: none;
}
.nav-side-menu.huomenta {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  background-color: #fff;
  position: fixed;
  width:inherit;
  top: 0px;
  height: 100%;
  border-right: 1px solid #ddd;
}

.nav-side-menu.huomenta .brand{
  background-color: #fff;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}

.nav-side-menu.huomenta ul .active,
.nav-side-menu.huomenta li .active{
  border-left: 3px solid $brand-primary;
  background-color: #777 !important;
  color: #fff !important;
}

.nav-side-menu.huomenta ul .sub-menu li,
.nav-side-menu.huomenta li .sub-menu li {
  background-color: #eee;
  border: none;
  line-height: 28px;
  margin-left: 0px;
}

.nav-side-menu.huomenta ul .sub-menu li:hover,
.nav-side-menu.huomenta li .sub-menu li:hover {
  background-color: #555758 !important;
    color: #fff !important;
}

.nav-side-menu.huomenta ul .sub-menu li.active,
.nav-side-menu.huomenta li .sub-menu li.active {
  background-color: #555758 !important;
  color: #fff !important;
}


.nav-side-menu.huomenta li {
  padding-left: 0px;
  border-left: 3px solid #555758;
  border-bottom: 1px solid #eee;
  color: #555758 !important;
}
.nav-side-menu.huomenta li a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  color:inherit;
}

.nav-side-menu.huomenta ul .sub-menu li.active,
.nav-side-menu.huomenta li .sub-menu li.active {
  color: $brand-primary !important;
  background-color: #eee !important;
  font-weight: 800;
}

.nav-side-menu.huomenta li:hover {
  border-left: 3px solid darken($brand-primary, 20%);
  background-color: #555758 !important;
  color: #fff !important;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}


.nav-side-menu.huomenta ul.darken-active,
.nav-side-menu.huomenta li.darken-active {
  border-left: 3px solid darken($brand-primary, 20%) !important;
  background-color: #777;
  color: #555758;
}

.huomenta .sub-menu li a {
    width: 85%;
}


@media (max-width: 767px) {
    .nav-side-menu.huomenta {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
    }
    .nav-side-menu.huomenta .toggle-btn {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 45px;
      z-index: 10 !important;
      padding: 3px;
      background-color: #fff;
      color: $brand-primary;
      width: 40px;
      text-align: center;
    }
    .brand.huomenta {
      text-align: left !important;
      font-size: 22px;
      line-height: 50px !important;
    }

    .brand .logo {
      padding-left: 20px;
      height: 40px !important;
    }
}

@media (min-width: 767px) {
    .nav-side-menu.huomenta .menu-list .menu-content {
      display: block;
    }
}




