body {
    //padding-top: $navbar-height;
    padding-top: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-attachment: fixed;
}

html{
  font-size:16px;  
  height: 100%;

}

.pdf {
  background: none !important;
}

.pdf tr {
  page-break-inside: avoid; 
}

.margin-top-0 {
    margin-top: 0;
}

.logo-container {
    padding:30px;
    height:140%;
}

.strike {
    text-transform: strike;
}

.checklist {
    li:before {
        content: "\f00c";
        font-family: 'FontAwesome';
        font-size: 80%;
    }
}

.modal {
    z-index: 1041;
}

.panel-header {
    padding: 0 15px;
}

.panel-default > .panel-heading {
    color: #95999f;
    background-color: inherit;
    text-transform: uppercase;
}
.panel-heading {
    color: #95999f;
    padding: 5px 15px;
    border: none; 
}

.above-panel-heading {
    color: #95999f;
    background-color: inherit;
    text-transform: uppercase;
}

.col-center-block {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dataTables_processing{
    text-align:center;
}

.float-e-margins .btn {
  margin-bottom: 5px;
}

a {
  font-family: "Varela Round", sans-serif;
}


.chevron-toggle .collapsing:first-child { display: none; }

.form-group .select2-container {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  display: table;
  table-layout: fixed;
}

div.slider {
    display: none;
    padding: 10px;
}
table.dataTable tbody td.no-padding {
    padding: 0;
}

.nav-tabs > li > a {
    color: #95999f;
}


// VERTICAL DIVIDER
.row.vdivide > [class*='col-']:not(:last-child):after {
  background: #eee;
  width: 1px;
  content: "";
  display:block;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  min-height: 70px;
  margin: 0;
}

.row.vdivide-m > [class*='col-']:not(:last-child):after {
  background: #eee;
  width: 1px;
  content: "";
  display:block;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  min-height: 70px;
  margin: -15px 0;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.showme{ 
  display: none;
}

.showhim:hover .showme{
  display : block;
}


.text-success {
    color: #32c17b;
}
.text-warning {
    color: #f8ac59;
}
.text-info {
    color: #23c0d1;
}
.text-danger {
    color: #ed5565;
}
.text-normal {
    color: #47525d;
}


.right-border-success {
   border-right: 3px solid #32c17b;
}
.right-border-warning {
   border-right: 3px solid #f8ac59;
}
.right-border-info {
   border-right: 3px solid #23c0d1;
}
.right-border-danger {
   border-right: 3px solid #ed5565;
}

.right-border-primary {
   border-right: 3px solid $brand-primary;
}

.right-border-default {
   border-right: 3px solid #95999f;
}

.left-border-success {
   border-left: 3px solid #32c17b;
}
.left-border-warning {
   border-left: 3px solid #f8ac59;
}
.left-border-info {
   border-left: 3px solid #23c0d1;
}
.left-border-danger {
   border-left: 3px solid #ed5565;
}

.left-border-primary {
   border-left: 3px solid $brand-primary;
}

.left-border-default {
   border-left: 3px solid #95999f;
}

.check
{
  opacity:0.5;
  border: solid 1px $brand-danger;
  
}





// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

.body-bg {
    background-size: cover ;
    background: #f9f9f9;
    background-image: url('/images/bg/moppi_background.jpg'); /* fallback */
    background: -moz-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(249,249,249,0)), color-stop(98%, rgba(249,249,249,0.98)), color-stop(100%, rgba(249,249,249,1)), color-stop(100%, rgba(249,249,249,1))),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F9F9F9', endColorstr='#F9F9F9',GradientType=0 ); /* ie6-9 */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;   
  @media #{$mq-medium} {
      background: -moz-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* ff3.6+ */
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(249,249,249,0)), color-stop(98%, rgba(249,249,249,0.98)), color-stop(100%, rgba(249,249,249,1)), color-stop(100%, rgba(249,249,249,1))),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* safari4+,chrome */
      background:-webkit-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* safari5.1+,chrome10+ */
      background: -o-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* opera 11.10+ */
      background: -ms-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* ie10+ */
      background:radial-gradient(ellipse at center, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_medium.jpg') no-repeat center center fixed ; /* w3c */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F9F9F9', endColorstr='#F9F9F9',GradientType=0 ); /* ie6-9 */
      -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media #{$mq-large} {
    background: -moz-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(249,249,249,0)), color-stop(98%, rgba(249,249,249,0.98)), color-stop(100%, rgba(249,249,249,1)), color-stop(100%, rgba(249,249,249,1))),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_large.jpg') no-repeat center center fixed ; /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F9F9F9', endColorstr='#F9F9F9',GradientType=0 ); /* ie6-9 */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media #{$mq-xlarge} {
    background: -moz-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(249,249,249,0)), color-stop(98%, rgba(249,249,249,0.98)), color-stop(100%, rgba(249,249,249,1)), color-stop(100%, rgba(249,249,249,1))),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xlarge.jpg') no-repeat center center fixed ; /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F9F9F9', endColorstr='#F9F9F9',GradientType=0 ); /* ie6-9 */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media #{$mq-xxlarge} {
    background: -moz-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(249,249,249,0)), color-stop(98%, rgba(249,249,249,0.98)), color-stop(100%, rgba(249,249,249,1)), color-stop(100%, rgba(249,249,249,1))),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(249,249,249,0) 1%, rgba(249,249,249,0.98) 98%, rgba(249,249,249,1) 100%, rgba(249,249,249,1) 100%),url('/images/bg/moppi_background_xxlarge.jpg') no-repeat center center fixed ; /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F9F9F9', endColorstr='#F9F9F9',GradientType=0 ); /* ie6-9 */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}


.wrapper {
  min-height:100%;
  position:relative;
}

.footer {
  position:absolute; 
  bottom:5px; 
  left: 50%;
  transform: translate(-50%, -50%);
}




