.cd-timeline-img.cd-cleaning {
  background: #25cbb6;
}

.cd-timeline-img .info {
    display: block;
    text-align:center;
    position: relative;
    top: 25%;
    font-weight: 700;
    color:white;
}

.cd-timeline-img img {
    display: none;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    text-align: right;
  }
  .cd-timeline-content .cd-date {
    text-align: left;
  }
  
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    text-align: left;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      text-align: right;
  }

  .cd-timeline-img .info {
    top: 10%;
  }

  .cd-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 35%;
    margin-left: -12px;
    margin-top: -12px;
	}
 
}




