.auth-container {
    width: 100%;
    color:white;
}

.auth-container h2 {
    color:white;
    text-align:center;
}

.avatar-container{
   text-align:center;
   padding: 30px;
}


.auth-container button {
    width: 100%;
    height: 70px;
}

.auth-container input{
    height: 70px;
    border-color: #1393c8;
    text-align:center;
}

 .auth-container input[type=checkbox]{
        height:70px;
        position: relative;
        vertical-align: middle;
        bottom: 1px;
}

.auth-container label{
   font-size:18px;
   color:white;
}

.reset-password {
	font-size: 130%;
	text-align:center;
	color:white;
}
.reset-password a {
    text-decoration:underline;
    color:white;
}
.reset-password a:hover {
    color: darken(white, 15%);
}
